@font-face {
  font-family: "Heading";
  /* src: url("./Geograph-Light.woff") format("woff"); */
  /* src: url("./Roobert-Light.otf") format("woff"); */
  /* src: url("./LabGrotesque-Light.otf") format("woff"); */
  /* src: url("./Basier-Square-Regular.otf") format("woff"); */
  src: url("./Söhne-Leicht.woff2") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Heading";
  /* src: url("./Geograph-Regular.woff") format("woff"); */
  /* src: url("./Roobert-Regular.otf") format("woff"); */
  /* src: url("./LabGrotesque-Regular.otf") format("woff"); */
  /* src: url("./Basier-Square-Regular.otf") format("woff"); */
  src: url("./Söhne-Buch.woff2") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Heading";
  /* src: url("./Geograph-Medium.woff") format("woff"); */
  /* src: url("./Roobert-Medium.otf") format("woff"); */
  /* src: url("./LabGrotesque-Medium.otf") format("woff"); */
  /* src: url("./Basier-Square-Medium.otf") format("woff"); */
  src: url("./Söhne-Kräftig.woff2") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Heading";
  /* src: url("./Geograph-Medium.woff") format("woff"); */
  /* src: url("./Roobert-Medium.otf") format("woff"); */
  /* src: url("./LabGrotesque-Medium.otf") format("woff"); */
  /* src: url("./Basier-Square-SemiBold.otf") format("woff"); */
  src: url("./Söhne-Halbfett.woff2") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Heading";
  /* src: url("./Geograph-Bold.woff") format("woff"); */
  /* src: url("./Roobert-Bold.otf") format("woff"); */
  /* src: url("./LabGrotesque-Bold.otf") format("woff"); */
  /* src: url("./Basier-Square-Bold.otf") format("woff"); */
  src: url("./Söhne-Dreiviertelfett.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

/* BODY */
@font-face {
  font-family: "Body";
  src: url("./AtlasGrotesk-Light.otf") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Body";
  src: url("./AtlasGrotesk-Regular.otf") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Body";
  src: url("./AtlasGrotesk-Medium.otf") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Body";
  src: url("./AtlasGrotesk-Bold.otf") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Body";
  src: url("./AtlasGrotesk-Bold.otf") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Body";
  src: url("./AtlasGrotesk-Black.otf") format("woff");
  font-weight: 800;
  font-style: normal;
}
