/*
  See: https://gist.github.com/cvan/6c022ff9b14cf8840e9d28730f75fc14
  See: https://webkit.org/blog/7929/designing-websites-for-iphone-x/

  Must have this meta tag in the <head> of your HTML:
  <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover">

  @supports(padding: max(0px)) {
    .post {
      padding-left: max(12px, env(safe-area-inset-left));
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
 */

@supports (padding-top: constant(safe-area-inset-top)) {
  body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left);
  }
}

@media (display-mode: fullscreen) {
  body {
    padding: 0;
  }
}

@media (display-mode: standalone) {
  body {
    padding: 0;
  }
}

/* Older browsers (which don't support CSS variables) */
/* body {
  height: 100%;
} */

.t-safe {
  top: env(safe-area-inset-top);
}
.pt-safe {
  padding-top: env(safe-area-inset-top);
}
.pb-safe {
  padding-bottom: env(safe-area-inset-bottom);
}
.px-safe {
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
