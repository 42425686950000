.picker-container {
  z-index: 1 !important;
}
.picker-container .picker-inner {
  color: #333; /* text.800 */
  font-size: 36px !important;
  font-family: "Heading";
  /* new Safari CSS created this bug 23-06-00 */
  -webkit-mask-box-image: initial !important;
  mask-image: linear-gradient(
    0deg,
    transparent,
    transparent 5%,
    #fff 20%,
    #fff 80%,
    transparent 95%,
    transparent
  );
}
.ConfigRowPickerHandicap .picker-inner::before {
  content: " ";
  height: 4px;
  width: 4px;
  background-color: black;
  border-radius: 999px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.picker-container .picker-column .picker-item {
  color: rgb(51 51 51 / 50%);
}
.picker-container .picker-column .picker-item.picker-item-selected {
  color: rgb(51 51 51 / 100%); /* text.800 */
  font-weight: 500;
}
