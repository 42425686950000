@media screen and (max-width: 959px) {
  /*
    Applied at chakra.breakpoints.xl.

    While this could be done with Chakra, it may prove useful to use the --screen-height measurement for edge cases… So, using a class site-wide as a fallback method for easy future iteration.
  */
  .screen-pb,
  .screen-pb--withinSponsor {
    padding-bottom: 4rem !important;
  }
  .screen-mb {
    margin-bottom: 4rem !important;
  }
  .screen-pb--sponsor,
  .sponsor-space .screen-pb {
    padding-bottom: 8.5rem !important;
  }
}

/*
  100vh limited fix on iOS
  https://github.com/postcss/postcss-100vh-fix

  Full fix with JS, but can't use with Chakra
  https://github.com/Faisal-Manzer/postcss-viewport-height-correction

  https://github.com/tailwindlabs/tailwindcss/discussions/4515#discussioncomment-4182377

  ps. `--vh` is still set in the public index.html file, but not used

  See new viewport units for iOS in Obsidian
 */

:root {
  /* --viewport-height: calc(var(--vh, 1vh) * 100); */
  /* vh, svh or cqh (chrome only)? */
  --viewport-height: 100vh;
}

.all-screen-h {
  height: var(--viewport-height);
}
/* The hack for Safari only (not Chrome, etc). Can't find any docs on this. Not sure it works? People keep saying it tho… */
/* @supports (-webkit-touch-callout: none) {
  body {
    min-height: fill-available;
    min-height: -webkit-fill-available;
  }
  .all-screen-h {
    height: fill-available;
    height: -webkit-fill-available;
  }
} */

/* tied to the room the element has available, not the viewport */
/* html {
  min-height: -webkit-fill-available;
}
body {
  min-height: -webkit-fill-available;
}
#root {
  min-height: -webkit-fill-available;
} */

/* https://tailwindcss.com/docs/screen-readers#class-reference */
.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}
